import { useOpenVideoPreview } from '/features/pageOnly/wavemakers/videoPreviewHooks'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'

/** @param {{ state: AnchorState, layoutClassName?: string }} _ */
export function Anchor({ state, layoutClassName = undefined }) {
  const { ref: visibilityRef, isInViewport } = useIsInViewport({ rootMargin: '0%' })
  const scrollDirection = useScrollDirectionState()
  const isMounted = useRenderOnMount()
  const fn = useOpenVideoPreview()

  React.useEffect(
    () => { if (isMounted && isInViewport && state) { fn(state[scrollDirection]) } },
    [isMounted, state, scrollDirection, fn, isInViewport]
  )

  return (
    <div ref={visibilityRef} className={layoutClassName} />
  )
}
function useScrollDirectionState() {
  const [scrollDirection, setScrollDirection] = React.useState('down')
  const lastScrollYRef = React.useRef(
    typeof window !== 'undefined' ? window.scrollY : 0
  )

  function updateScrollDirection() {
    const { scrollY } = window
    const direction = scrollY > lastScrollYRef.current ? 'down' : 'up'
    const deltaY = Math.abs(scrollY - lastScrollYRef.current)

    if (direction !== scrollDirection && deltaY > 5) {
      setScrollDirection(direction)
    }

    lastScrollYRef.current = scrollY > 0 ? scrollY : 0
  }

  React.useEffect(() => {
    window.addEventListener('scroll', updateScrollDirection)

    return () => {
      window.removeEventListener('scroll', updateScrollDirection)
    }
  }, [scrollDirection])

  return /** @type {'up' | 'down'} */ (scrollDirection)
}

/**
 * @typedef {Object} AnchorState
 * @property {'expanded' | 'collapsed' | 'closed'} up
 * @property {'expanded' | 'collapsed' | 'closed'} down
 */
